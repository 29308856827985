@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300&family=Lato:wght@300&display=swap");
.hero-content {
  background-image: url("../../assets/images/about/coreteam.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 589px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  .rhombus {
    position: absolute;
    top: -5rem;
    right: 0;
    width: 18rem;
    z-index: -1;
  }
  .semicircle1 {
    position: absolute;
    bottom: 5rem;
    left: 0;
    width: 10rem;
    z-index: -1;
  }
  .img-texts {
    text-align: center;
    font-family: "Inter", sans-serif;
    .img-text1 {
      color: #ff9100;
      font-weight: 700;
      font-family: "Inter", sans-serif;
    }
    .img-text2 {
      color: #ffffff;
      font-size: 2.7rem;
      font-weight: 700;
      margin: 20px 0;
      .span2 {
        color: #ff9100;
      }
    }
    .img-text3 {
      font-size: 1rem;
      color: white;
      font-family: $fontFamily;
      margin: 10px 0;
    }
    .img-btn {
      width: 155px;
      height: 45px;
      border-radius: 38px;
      background: white;
      margin-top: 5px;
      font-weight: 600;
      color: $primaryColor;
    }
  }
}
.main {
  width: 100%;
  font-family: $fontFamily;
  position: relative;
  .rhombus2 img {
    position: absolute;
    right: 0;
    top: -3rem;
    z-index: -1;
    width: 18rem;
  }
  .rhombus3 img {
    position: absolute;
    left: 0;
    top: 60rem;
    z-index: -10;
    width: 18rem;
  }
  .section-line {
    line-height: 5px;
    margin-bottom: 15px;
  }
  .section-text2 {
    font-size: 1.4rem;
    margin-top: 10px;
    font-weight: 700;
  }
  .section1 {
    margin: 50px 20% 0 20%;
    text-align: center;
    .section1-text3 {
      margin-top: 20px;
    }
  }
  .section2 {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin: 30px;
    .section2-content {
      width: 65%;
      margin: 30px auto;
    }
    .section2-img img {
      width: 100%;
      height: 250px;
    }
  }
  .section3 {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin: 30px;
    .section3-content {
      width: 65%;
      margin: 30px auto;
    }
    .section3-img img {
      width: 100%;
      height: 250px;
    }
  }
  .about-getinfo-btn {
    width: 135px;
    height: 45px;
    border-radius: 38px;
    display: none;
    background: linear-gradient(
      100.33deg,
      #2549c9 6.84%,
      rgba(119, 148, 254, 0.71) 94.24%
    );
    color: white;
    margin-top: 20px;
    border: none;
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.1);
  }
  .section4 {
    text-align: center;
    width: auto;
    margin: 0 auto;
    margin-bottom: 200px;
    .section4-content {
      align-items: center;
    }
    .section4-boxes {
      margin: 0 auto;
      width: 80%;
      display: grid;
      place-items: center;
      justify-content: center;
      gap: 20px;
      grid-template-columns: repeat(3, 260px);
      margin-top: 50px;
      justify-items: center;
      align-content: center;
      .section4-box:nth-child(3n-1) {
        position: relative;
        top: 50px;
        align-items: center;
      }
      .section4-box:nth-child(3n-3) {
        position: relative;
        top: 100px;
      }
      .section4-box {
        justify-items: center;
        height: 335px;
        width: 220px;
        background-color: #ffffff;
        border-radius: 10px;
        box-shadow: 0px 4px 67px 20px rgba(0, 0, 0, 0.04);
        padding: 20px;
        padding-bottom: 40px;
        .box-head {
          display: flex;
          align-items: center;
          .box-heading {
            color: #0c6ed3;
            font-weight: 500;
            margin-left: 10px;
          }
          .box-logo {
            width: 50px;
            img {
              width: 40px;
            }
          }
        }
        .box-info {
          text-align: left;
          padding-left: 20px;
          ul li {
            margin-top: 10px;
            list-style: disc;
            font-size: 0.95rem;
            color: #4b4b4b;
            span {
              font-weight: 600;
            }
          }
        }
      }
    }
  }
  .section5 {
    background: #e7f1fb;
    width: 100%;
    height: 250px;
    text-align: center;
    .section5-boxes {
      display: flex;
      overflow: hidden;
      .section5-box {
        width: 350px;
        background: #ffffff;
        box-shadow: 2.2029972076px 2.9373297691px 53.6062698364px 0px
          rgba(0, 0, 0, 0.0588235294);
        border-radius: 10px;
        margin: 1.5rem 30px;
        text-align: center;
        padding: 10px;
        box-sizing: border-box;
        border-top-right-radius: 30px;
      }
    }
  }
  .section6 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 130px 15%;
    text-align: center;
    .section6-img {
      margin-top: 50px;
      img {
        width: 95%;
      }
    }
  }
  .heading-blue {
    color: $primaryColor;
    font-weight: 600;
    font-size: 1.17rem;
    padding: 15px 0px 10px;
  }
  .rhombus4 img {
    width: 18rem;
    position: absolute;
    left: 0;
    bottom: 12rem;
    z-index: -1;
  }
}

//Responsive
@media only screen and (min-width: 781px) and (max-width: 900px) {
  .main {
    .section4 {
      width: 90%;
      margin-bottom: 150px;
      .section4-boxes {
        gap: 15px;
        grid-template-columns: repeat(3, 220px);
        width: 80%;
        .section4-box {
          width: 180px;
          height: 250px;
          padding-bottom: 40px;
          .box-info ul li {
            font-size: 0.7rem;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 780px) {
  .hero .hero-content {
    .img-btn {
      width: 115px;
      height: 37px;
      font-size: 13px;
    }
    .img-text1,
    .img-text3 p {
      font-size: 12px;
      display: inline;
      span {
        display: block;
      }
    }
    .img-text2 {
      font-size: 25px;
      p {
        display: inline;
      }
      .span1 {
        display: block;
      }
    }
  }
  .main {
    .section1 {
      margin: 50px 5% 0 5%;
    }
    .section2 {
      margin: 30px 6% 50px 6%;
      flex-direction: column;
      .section2-content {
        width: 100%;
      }
      .section2-img {
        height: 220px;
      }
    }
    .section3 {
      flex-direction: column-reverse;
      margin: 30px 6% 80px 6%;
      .section3-content {
        width: 100%;
      }
      .section3-img {
        height: 220px;
      }
    }
    .section4 {
      margin-top: 70px;
      width: 100%;
      margin-bottom: 70px;
      .span {
        display: block;
      }
      .section4-boxes {
        display: flex;
        flex-direction: column;
        gap: 25px;
        .section4-box:nth-child(3n-1) {
          top: 0;
        }
        .section4-box:nth-child(3n-3) {
          top: 0;
        }

        .section4-box {
          height: 315px;
          padding-bottom: 0;
          margin: 0;
          .box-info ul li {
            margin-top: 10px;
            list-style: disc;
            font-size: 0.85rem;
            color: #4b4b4b;
          }
        }
      }
    }
    .section5 {
      padding: 30px 0;
      height: 180px;
      .section5-boxes {
        padding-left: 5px;
        margin-top: 30px;
        .section5-box {
          margin: 10px;
          img {
            width: 50px;
          }
        }
      }
    }
    .section6 {
      margin: 50px 0;
      .section6-img {
        margin: 50px 10px;
      }
    }
    .heading-blue {
      color: #0c6ed3;
      font-weight: 600;
      font-size: 0.85rem;
      padding: 0;
      margin-bottom: 5px;
    }
    .section-text2 {
      margin: 0;
      margin-top: 10px;
      font-size: 1rem;
    }
    .box-none {
      display: none;
    }
  }
  .text {
    font-family: "Lato", sans-serif;
    font-weight: 900;
    color: #3a3a3a;
    word-spacing: 1px;
    font-size: 0.95rem;
    letter-spacing: 0.2 px;
    line-height: 20px;
  }
}
