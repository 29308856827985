.home-main {
  background-color: White;
}

.main-img {
  background-image: url("../../assets/images/Rectangle\ 4.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 500px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.home-about-us {
  color: $primaryColor;
  font-size: 1.3rem;
  margin-top: 8vw;
}
.home-about-us-title {
  font-size: 1.2rem;
  font-family: $fontFamily;
  margin-top: 15px;
}

.home-about-btn {
  width: 135px;
  height: 45px;
  top: 261px;
  padding: 15px, 20px, 15px, 20px;
  border-radius: 38px;
  gap: 10px;
  background: linear-gradient(
    100.33deg,
    #2549c9 6.84%,
    rgba(119, 148, 254, 0.71) 94.24%
  );
  color: #ffffff;
}
.About-us-container {
  display: flex;
  justify-content: space-around;
  margin: 30px;
}

#home-about-us {
  margin: 30px 100px;
}
@media screen and (max-width: 768px) {
  #home-about-us {
    margin: 30px 0;
  }
}

.home-img-text {
  color: #ff9100;
  font-weight: 600;
}
.home-img-content {
  color: #ffffff;
  font-size: 2.7rem;
  font-weight: 700;
}
.img-p-content {
  font-size: 1rem;
  color: white;
}

.home-service-heading {
  text-align: center;
  font-family: $fontFamily;
  font-size: 1.17rem;
  font-weight: 600;
  letter-spacing: 0em;
  color: #0c6ed3;
  padding: 15px 0px 10px;
}
.home-service-custom {
  text-align: center;
  font-family: $fontFamily;
  font-size: 1.2rem;
}
.home-service-box {
  width: 350px;
  background: #ffffff;
  box-shadow: 0px 4px 67px 6px #0000000a;
  border-radius: 10px;
  margin: 0.5rem 0px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* padding: 10px; */
}
.service {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin: 30px 0px;
}
.container {
  margin: 30px;
}
.home-service-button {
  width: 118px;
  height: 34px;
  border: none;
  background: none;
  color: #0c6ed3;
}
.underline {
  text-align: center;
  line-height: 5px;
}
.Technology {
  display: flex;
  overflow: hidden;
}
.technology-box {
  width: 350px;
  background: #ffffff;
  box-shadow: 2.2029972076416016px 2.9373297691345215px 53.60626983642578px 0px
    #0000000f;
  border-radius: 10px;
  margin: 1.5rem 30px;
  text-align: center;
  padding: 10px;
  box-sizing: border-box;
  border-top-right-radius: 30px;
}
.tech-container {
  background: #e7f1fb;
  width: 100%;
  // height: 250px;
}
.explore {
  font-family: $fontFamily;
  font-size: 1rem;
  font-weight: 600;
  line-height: 37px;
  letter-spacing: 0em;
  text-align: left;
  color: #0c6ed3;
}
.service-container {
  display: flex;
  justify-content: space-around;
}
.it-services {
  font-size: 1.2rem;
  font-family: $fontFamily;
}
.it-service-company {
  margin: 30px;
}
.underline1 {
  line-height: 5px;
}

.projects-stats {
  margin-top: 20px;
  display: flex;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}
.stat-item {
  padding: 10px;
  flex: 1;
  font-size: 15px;
}
.center-stat-item {
  border-style: none solid;
  border-color: $primaryColor;
}

.home-contact-us-button {
  width: 135px;
  height: 45px;
  border-radius: 38px;
  background: linear-gradient(
    100.33deg,
    #2549c9 6.84%,
    rgba(119, 148, 254, 0.71) 94.24%
  );
  color: white;
  margin-top: 20px;
  border: none;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.1);
}

.home-getinfo-btn {
  width: 155px;
  height: 45px;
  border-radius: 38px;
  background: white;
  color: #0c6ed3;
  margin-top: 5px;
  font-weight: 600;
}
.arrow {
  margin-left: -15px;
  margin-bottom: 0px;
  width: 12px;
}
.on-img-text {
  text-align: center;
}

.home-s-content {
  padding: 1rem;
}
.home-s-heading {
  font-weight: 600;
}
.About-uss {
  color: var(--pc-primary-color, #0c6ed3);
  font-family: Noto Sans;
}
.c-content {
  border: 3px solid #0c6ed3;
  border-style: none none none solid;
  padding: 5px;
  margin-top: 10px;
}

// --------------responsive-------------//
@media screen and (max-width: 768px) {
  .About-us-container {
    display: flex;
    justify-content: space-around;
    margin: 30px;
    flex-direction: column;
  }
  .stat-item {
    font-size: 15px !important;
  }
  .service-container {
    display: flex;
    justify-content: space-around;
    flex-direction: column;
  }
}
