@import url("https://fonts.googleapis.com/css2?family=Inter:wght@700&family=Roboto&display=swap");
.contactInfoBtn {
  width: 135px;
  height: 45px;
  top: 261px;
  padding: 15px 20px 15px 20px;
  border-radius: 38px;
  border: none;
  gap: 10px;
  background: linear-gradient(
    100.33deg,
    #2549c9 6.84%,
    rgba(119, 148, 254, 0.71) 94.24%
  );
  color: #ffffff;
  margin-top: 45px;
}
.contactInfo1 {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 20px 0;
  .circle1 {
    position: absolute;
    left: 0;
    bottom: 4rem;
    width: 8rem;
    z-index: -1;
  }
  .contactInfoText {
    width: 40%;
    h1 {
      color: #ff9100;
      font-family: "Inter", sans-serif;
      font-weight: 700;
      font-size: 1.5rem;
    }
    .contactInfo1Head {
      font-family: "Inter", sans-serif;
      font-size: 2.6rem;
      font-weight: 700;
      color: #000000;
      padding: 20px 0;
      padding-right: 80px;
      span {
        color: #ff9100;
      }
    }
    p {
      font-family: $fontFamily;
      font-weight: 500;
      font-size: 1.15rem;
      color: #6c6c6c;
    }
  }
  .contactInfoImage {
    width: 30%;
    img {
      width: 100%;
    }
  }
}
.contactInfo2 {
  position: relative;
  margin-top: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 100px;
  .rombhus {
    position: absolute;
    right: 0;
    bottom: -10rem;
    width: 14rem;
    z-index: -1;
  }
  .contactInfoBoxes {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 85%;
    .contactInfoBox {
      width: 28%;
      background-color: #ffffff;
      margin: 15px 10px;
      height: 210px;
      border-radius: 10px;
      box-shadow: 0px 4px 67px 20px rgba(0, 0, 0, 0.04);
      display: flex;
      flex-direction: column;
      padding: 30px;
      h3 {
        color: #0c6ed3;
        text-align: center;
        font-family: $fontFamily;
        font-weight: 600;
        font-size: 1.25rem;
      }
      .underline {
        align-self: center;
      }
      .contactInfoBoxContent {
        display: flex;
        align-items: center;
        padding: 10px 0;
        color: #6d6d6d;
        font-family: "roboto", "sans-serif";
        font-weight: 400;
        img {
          padding-right: 10px;
        }
      }
      .contactInfoBoxContent1 {
        display: flex;
        align-items: center;
        padding: 10px 0;
        color: #6d6d6d;
        font-family: "roboto", "sans-serif";
        font-weight: 400;
        margin-top: 20px;
        img {
          padding-right: 10px;
        }
      }
      a {
        color: #6d6d6d;
      }
      .contactInfoBoxContent3 {
        display: flex;
        align-items: center;
        padding: 10px 0;
        color: #6d6d6d;
        font-family: "roboto", "sans-serif";
        font-weight: 400;
        justify-content: center;
        flex-wrap: wrap;
        .contentSocialImage {
          width: 4rem;
          height: 4.5rem;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 8px 20px;
          border-radius: 10px;
          box-shadow: 0px 4px 26px 0px #00000021;
        }
      }
    }
  }
  .img-bg {
    background-color: #edf7f6;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    img {
      margin-left: 10px;
    }
  }
}
#contact-form {
  position: relative;
  margin: 80px 0px 80px 2%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  .circle2 {
    position: absolute;
    right: 0;
    bottom: -10rem;
    width: 9rem;
    z-index: -1;
  }
  .form-div {
    width: 40%;
    form {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .formHeading {
        align-self: flex-start;
        h3 {
          color: #0c6ed3;
          font-family: $fontFamily;
          font-weight: 600;
          font-size: 1.25rem;
          padding-bottom: 10px;
        }
        p {
          padding: 2px 0;
          font-family: $fontFamily;
          font-weight: 600;
          font-size: 1.4rem;
        }
        img {
          padding-bottom: 10px;
        }
      }
      input {
        box-sizing: border-box;
        border: 1px solid #8fbceb;
        border-radius: 5px;
        color: #1c1e53;
        font-size: 1rem;
        padding: 15px 45px;
        width: 100%;
      }
      .form-box {
        position: relative;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        width: 100%;
        border-radius: 5px;
        padding: 0.5rem;
        padding-left: 3px;
        .box-A {
          width: 47%;
          padding: 15px 45px;
        }
        img {
          position: absolute;
          top: 50%;
          left: 3%;
          transform: translateY(-50%);
        }
        .textarea-svg {
          position: absolute;
          top: 25%;
          left: 3%;
          transform: translateY(-50%);
        }
      }
      #text-area {
        border: 1px solid #8fbceb;
        color: #1c1e53;
        height: 8vw;
        width: 100%;
        font-size: 1rem;
        padding: 15px 40px;
      }
      button {
        align-self: flex-start;
      }
    }
  }
}
.form-image {
  width: 40%;
  img {
    width: 100%;
  }
}
@media only screen and (max-width: 780px) {
  .contactInfo1 {
    flex-direction: column-reverse;
    .circle1 {
      bottom: auto;
      width: 8rem;
    }
    .contactInfoImage {
      width: 52%;
    }
    .contactInfoText {
      width: 85%;
      .contactInfo1Head {
        font-size: 2rem;
        padding: 20px 0;
        padding-right: 0px;
      }
    }
  }
  .contactInfo2 {
    margin-top: 30px;
    .rombhus {
      width: 12rem;
      bottom: auto;
    }
    .contactInfoBoxes {
      flex-direction: column;
      .contactInfoBox {
        width: 80%;
        margin: 15px 14px;
        height: auto;
        .contactInfoBoxContent3 {
          .contentSocialImage {
            width: 3rem;
            height: 3.1rem;
          }
        }
      }
    }
  }
  #contact-form .form-div form .form-box .box-A {
    padding: 15px 5px 15px 37px;
  }
  #contact-form {
    flex-direction: column;
    margin: 50px 0;
    .form-div {
      width: 85%;
      form {
        .form-box {
          #text-area {
            height: 6rem;
          }
        }
      }
    }
    .form-image {
      width: 90%;
      bottom: 8rem;
      position: absolute;
      opacity: 0.1;
    }
  }
}
@media only screen and (min-width: 781px) and (max-width: 1410px) {
  .contactInfo2 .contactInfoBoxes {
    flex-wrap: wrap;
    .contactInfoBox {
      width: 35%;
      height: 200px;
    }
  }
  .contactInfo2
    .contactInfoBoxes
    .contactInfoBox
    .contactInfoBoxContent3
    .contentSocialImage {
    width: 3.1rem;
    height: 3.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 8px 20px;
  }
}
