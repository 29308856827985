@import url("https://fonts.googleapis.com/css2?family=Inter&family=Lato&family=Noto+Sans&display=swap");
.seo {
  position: relative;
}
.seo-circle1 {
  position: absolute;
  z-index: -100;
  top: 10rem;
  width: 10rem;
}
.seo-circle2 {
  position: absolute;
  z-index: -100;
  top: 42rem;
  right: 0;
  width: 15rem;
}
.seo-circle4{
  position: absolute;
  z-index: -100;
  top: 47rem;
  left: 0;
  width: 15rem;
  display: none;
}
.seo-circle3 {
  position: absolute;
  z-index: -100;
  top: 100rem;
  width: 12rem;
}
.seo-contact-us-button {
  width: 160px;
  height: 50px;
  border-radius: 38px;
  background: linear-gradient(
    100.33deg,
    #2549c9 6.84%,
    rgba(119, 148, 254, 0.71) 94.24%
  );
  color: white;
  margin-top: 20px;
  border: none;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.1);
  font-size: 1.2rem;
}
.seo-intro {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin: 0 2% 50px 7%;
  font-family: "Inter", sans-serif;
  margin-bottom: 0;
  padding: 70px 0;
  .seo-intro-content {
    width: 54%;
    .seo-intro-blueText {
      color: #0c6ed3;
      font-weight: 700;
      font-size: 1.5rem;
    }
    .seo-intro-content-text2 {
      font-size: 2.8rem;
      margin: 10px 0;
      font-weight: 700;
      span {
        color: #ff9100;
      }
      .block1 {
        display: block;
      }
    }
    .seo-intro-content-text3 {
      margin: 20px 0 10px 0;
      color: #6c6c6c;
      font-weight: 500;
      font-family: "Noto Sans", sans-serif;
      font-size: 1.2rem;
    }
  }
  .seo-intro-img {
    width: 45%;
    .seo-img1 {
      width: 95%;
      margin: 0 auto;
      float: left;
    }
  }
}
.seo-underline {
  text-align: center;
  line-height: 15px;
}
.seo-top-custom {
  text-align: center;
  font-family: "Noto Sans", sans-serif;
  strong {
    font-size: 1.8rem;
  }
}
.seo-top-heading {
  text-align: center;
  font-family: "Noto Sans", sans-serif;
  font-size: 1.2rem;
  font-weight: 600;
  color: #0c6ed3;
  padding: 15px 0px 10px;
}
.seo-container {
  margin: 0 3%;
  .seo-design {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin: 0 3% 50px;
    font-family: "Inter", sans-serif;
    .seo-top-design-heading {
      font-family: "Noto Sans", sans-serif;
      font-size: 1.2rem;
      font-weight: 600;
      color: #0c6ed3;
      padding: 15px 0px 0px;
    }
    .seo-design-underline {
      line-height: 5px;
      margin-top: -10px;
    }
    .seo-design-content {
      width: 50%;
      .seo-design-content-custom {
        margin: 10px 0;
      }
      strong {
        font-size: 1.8rem;
        font-family: "Noto Sans", sans-serif;
      }
      .seo-design-content-description {
        margin: 20px 0 10px 0;
        color: #6c6c6c;
        font-weight: 500;
        font-family: "Noto Sans", sans-serif;
        font-size: 1.125rem;
      }
      .seo-design-icons {
        display: flex;
        justify-content: center;
        margin-top: 20px;
        align-items: center;
        width: fit-content;
        height: 50px;
        gap: 10px;
        .seo-design-icon {
          justify-content: center;
          width: 35px;
          display: flex;
          align-items: center;
          box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.1);
          border-radius: 5px;
          padding: 5px;
          height: 35px;
          img {
            width: 30px;
          }
        }
      }
    }
    .seo-design-imgs {
      width: 45%;
      .seo-design-img {
        width: 75%;
        margin: 0 auto;
      }
    }
  }
  .seo-marketing {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin: 0 3%;
    font-family: "Inter", sans-serif;
    .seo-top-marketing-heading {
      font-family: "Noto Sans", sans-serif;
      font-size: 1.2rem;
      font-weight: 600;
      color: #0c6ed3;
      padding: 15px 0px 0px;
    }
    .seo-marketing-underline {
      line-height: 5px;
      margin-top: -10px;
    }
    .seo-marketing-content {
      width: 50%;
      align-items: center;
      .seo-marketing-content-custom {
        margin: 10px 0;
      }
      strong {
        font-size: 1.8rem;
        font-family: "Noto Sans", sans-serif;
      }
      .seo-marketing-content-description {
        margin: 20px 0 10px 0;
        color: #6c6c6c;
        font-weight: 500;
        font-family: "Noto Sans", sans-serif;
        font-size: 1.125rem;
      }
      .seo-marketing-icons {
        display: flex;
        justify-content: center;
        margin-top: 20px;
        align-items: center;
        width: fit-content;
        height: 50px;
        gap: 10px;
        .seo-marketing-icon {
          justify-content: center;
          width: 35px;
          display: flex;
          align-items: center;
          box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.1);
          border-radius: 5px;
          padding: 5px;
          height: 35px;
          img {
            width: 28px;
          }
        }
      }
    }
    .seo-marketing-imgs {
      width: 45%;
      .seo-marketing-img {
        width: 75%;
        float: right;
        margin: 0 auto;
      }
    }
  }
}
.seo-solutions {
  margin: 50px auto;
  margin-bottom: 120px;
}
.seo-boxes {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 30px 0;
  column-gap: 15px;
}
.seo-category {
  margin: 50px auto;
}
.seo-category-box {
  width: 270px;
  box-shadow: 0px 2px 59px 0px #00000021;
  border-radius: 10px;
  margin: 0.5rem 0px;
  box-sizing: border-box;
  padding: 10px 5px;
  background-color: #ffffff;
  position: relative;
  display: flex;
  align-items: center;
  transition: background-color;
  .seo-category-box-text {
    color: #0c6ed3;
    font-size: 1.1rem;
    font-weight: 500;
    margin-left: 5px;
    font-family: "Noto Sans", sans-serif;
  }
}
.seo-category-box:hover {
  background-color: #0c6ed3;
  transition-duration: 0.3s;
  .seo-category-box-text {
    color: #fff;
  }
}
.seo-benifits {
  font-family: "Noto Sans", sans-serif;
  .seo-boxes {
    margin: 40px 0;
    justify-content: center;
  }
  .seo-benifits-box {
    width: 270px;
    box-shadow: 0px 4px 67px 6px rgba(0, 0, 0, 0.0392156863);
    border-radius: 10px;
    margin: 0.5rem 0px;
    box-sizing: border-box;
    padding: 10px 5px;
    padding-bottom: 5px;
    position: relative;
    text-align: left;
    background-color: white;
    overflow: hidden;
    z-index: 1;
    .seo-benifits-box-top {
      display: flex;
      align-items: center;
      .seo-benifits-box-top-heading {
        font-weight: 600;
        font-size: 1rem;
        margin-left: 10px;
      }
    }
    .seo-benifits-box-content {
      color: #4b4b4b;
      font-size: 0.75rem;
      padding: 0 0rem 0.2rem 0.8rem;
    }
  }
  .seo-benifits-box::before {
    content: "";
    position: absolute;
    background: #b4d2f1;
    right: -50px;
    bottom: -50px;
    width: 100px;
    height: 100px;
    border-radius: 50px;
    transition: all 0.3s;
    z-index: -1;
  }
  .seo-benifits-box:hover::before {
    background: #0b64c0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    border-radius: 0px;
  }
  .seo-benifits-box:hover .seo-benifits-box-top .seo-benifits-box-top-heading,
  .seo-benifits-box:hover .seo-benifits-box-content {
    color: #ffffff;
  }
}
.seo-solutions-box {
  width: 250px;
  background: #ffffff;
  border-radius: 10px;
  margin: 0.5rem 0px;
  text-align: center;
  padding: 15px;
  border: 1px solid rgba(174, 172, 172, 0.705);
  border-top: 0px solid;
  border-bottom: 0;
  box-shadow: -1px 1px 1px 0px rgba(0, 0, 0, 0.2509803922);
  .seo-solutions-box-img {
    background-color: #8fbceb;
    margin: 0 auto;
    width: 70px;
    height: 70px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .seo-solutions-box-heading {
    text-align: center;
    margin: 10px 0;
    font-family: "Noto Sans", sans-serif;
    font-size: 1.2rem;
    font-weight: 600;
    color: #0c6ed3;
    padding: 15px 0px 10px;
  }
  .seo-solutions-box-content {
    color: #3a3a3a;
    font-family: "Lato", sans-serif;
    margin: 10px 0px 20px 0px;
    line-height: 25px;
    font-size: 1.1rem;
  }
}

@media screen and (min-width: 600px) and (max-width: 900px) {
  .seo-intro {
    flex-direction: column-reverse;
    padding: 0;
    .seo-intro-content {
      width: 90%;
      .seo-intro-content-text2 {
        font-size: 2.5rem;
      }
    }
    .seo-intro-img {
      width: 70%;
    }
  }
  .seo-container {
    .seo-design {
      flex-direction: column-reverse;
      margin-top: 20px;
      margin-bottom: 0;
      .seo-design-imgs {
        width: 55%;
        margin-top: 20px;
        .seo-design-img {
          width: 100%;
        }
      }
      .seo-design-content {
        width: 90%;
        margin-top: 30px;
      }
    }
    .seo-marketing {
      flex-direction: column;
      margin-top: 60px;
      .seo-marketing-imgs {
        width: 60%;
        .seo-marketing-img {
          width: 100%;
        }
      }
      .seo-marketing-content {
        width: 90%;
      }
    }
  }
}
@media screen and (max-width: 600px) {
  .seo-contact-us-button {
    width: 120px;
    height: 40px;
    font-size: 0.8rem;
  }
  .seo-intro {
    margin: 0 5%;
    flex-direction: column-reverse;
    padding: 0;
    .seo-intro-img {
      width: 100%;
      .seo-img1 {
        width: 100%;
        float: none;
      }
    }
    .seo-intro-content {
      width: 100%;
      .seo-intro-content-text1 {
        font-size: 0.95rem;
        padding: 0;
        margin-bottom: 10px;
      }
      .seo-intro-content-text2 {
        font-size: 1.5rem;
        margin: 0;
      }
      .seo-intro-content-text3 {
        margin: 10px 0;
        font-size: 0.8rem;
      }
    }
  }
  .seo-top-custom strong {
    font-size: 1.1rem;
  }
  .seo-top-heading {
    font-size: 0.9rem;
  }

  .seo-container {
    margin: 0 3%;
    .seo-top-heading {
      padding: 0;
    }
    .seo-design {
      flex-direction: column-reverse;
      margin: 0 4%;
      .seo-design-imgs {
        width: 100%;
        text-align: center;
        margin-top: 30px;
        .seo-design-img {
          width: 100%;
        }
      }
      .seo-design-content {
        width: 100%;
        .seo-top-design-heading {
          font-size: 0.9rem;
          margin-top: 20px;
        }
        .seo-design-content-description {
          margin: 10px 0;
          font-size: 0.8rem;
        }
        strong {
          font-size: 1.1rem;
        }
        .seo-design-icons {
          gap: 8px;
          margin-top: 0;
          .seo-design-icon {
            width: 28px;
            padding: 0;
            height: 28px;
            img {
              width: 22px;
            }
          }
        }
      }
    }

    .seo-marketing {
      flex-direction: column;
      margin: 0 4%;
      .seo-marketing-imgs {
        width: 100%;
        text-align: center;
        margin-top: 30px;
        .seo-marketing-img {
          width: 100%;
        }
      }
      .seo-marketing-content {
        width: 100%;
        .seo-top-marketing-heading {
          font-size: 0.9rem;
          margin-top: 20px;
        }
        .seo-marketing-content-description {
          margin: 10px 0;
          font-size: 0.8rem;
        }
        strong {
          font-size: 1.1rem;
        }
        .seo-marketing-icons {
          gap: 8px;
          margin-top: 0;
          .seo-marketing-icon {
            width: 28px;
            padding: 0;
            height: 28px;
            img {
              width: 22px;
            }
          }
        }
      }
    }
    .seo-benifits {
      .seo-boxes {
        margin: 0;
        margin-top: 30px;
        column-gap: 5px;
        padding: 0;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        overflow: hidden;
        img {
          width: 40px;
        }
      }
      .seo-benifits-box {
        width: 165px;
        padding: 0.8rem 0.5rem;
        padding-bottom: 5px;
        align-items: center;
        .seo-benifits-box-top {
          flex-direction: column;
          .seo-benifits-box-top-heading {
            font-size: 0.7rem;
            text-align: center;
            span {
              display: inline;
            }
          }
        }
      }
      .seo-benifits-box::before {
        right: -30px;
        bottom: -30px;
        width: 70px;
        height: 70px;
      }
      .seo-benifits-box-content {
        display: none;
      }
    }
    .seo-category {
      .seo-boxes {
        margin: 30px 0;
        column-gap: 5px;
        padding: 0;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        overflow: hidden;
        .seo-category-box {
          width: 165px;
          padding:6px 3px;
          .seo-category-box-img img {
            width: 38px;
          }
          .seo-category-box-text {
            font-size: 12px;
            margin-left: 0;
          }
        }
      }
    }
  }
  .seo-solutions {
    .seo-boxes {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      grid-gap: 10px;
      overflow: hidden;
      margin: 30px 0;
      .seo-solutions-box {
        width: 150px;
        padding: 15px 5px;
        .seo-solutions-box-img {
          width: 45px;
          height: 45px;
          img {
            width: 30px;
            height: 30px;
          }
        }
        .seo-solutions-box-heading {
          font-size: 0.9rem;
          margin: 0;
        }
        .seo-solutions-box-content {
          font-size: 0.7rem;
          margin: 0;
          line-height: 17px;
        }
      }
    }
  }
  .seo-circle1 {
   display: none;
  }
  .seo-circle2 {
    top: 38rem;
    width: 8rem;
  }
  .seo-circle4{
    display: block;
    top: 77rem;
    width: 8rem;
    rotate: (180deg);
  }
  .seo-circle3 {
    top: 117rem;
    right: 0;
    rotate: (180deg);
    width: 5rem;
  }
}
