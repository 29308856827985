.header.scrolled {
  box-shadow: 1px 2px 2px rgb(197, 195, 195); /* Add your desired border style here */
}
nav {
  position: sticky;
  top: 0;
  z-index: 999;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 70px;
  .nav-right {
    display: flex;
    align-items: center;
    gap: 30px;
    .upper-mobile-navbar {
      display: none;
    }

    ul {
      display: flex;
      align-items: center;
      gap: 30px;

      div {
        display: flex;
        align-items: center;
        gap: 5px;

        li {
          color: var(--pc-gray, #8b8b8b);
          font-family: Noto Sans;
          font-size: 1.125rem;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          border: none;
        }
        li:hover {
          color: #0c6ed3;
        }
        li:hover + .icon {
          // visibility:visible;
          display: block;
        }
        .icon {
          // visibility: hidden;
          display: none;
        }
      }
      div:hover {
        color: #0c6ed3;
      }
    }
    .contact-btn {
      all: unset;
      background-color: #0c6ed3;
      border-radius: 2.5rem;
      padding: 7px 25px 9px 25px;
      color: #fff;
      cursor: pointer;
    }
    .mob-navitem {
      display: none;
    }
    .mob-navlinks {
      display: none;
    }
  }

  .nav-right li {
    padding: 25px 0;
  }
  .menu-icon {
    display: none;
  }
  .multi-dropdown {
    position: absolute;
    left: 15vw;
    justify-content: center;
    padding: 10px 0 20px 0;
    border-radius: 1.25rem;
    background: #fff;
    box-shadow: 67px 67px 128px 0px rgba(0, 0, 0, 0.25);
    top: 72px;
    width: 70vw;
    // padding: 10px 0 20px 0;
    ul {
      gap: 60px;
    }
    ul > .left,
    ul > .right {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;
    }
    li {
      display: flex !important;
      align-items: center !important;
      gap: 10px !important;
      padding: 0;
    }
  }
  #solutions-dropdown,
  #services-dropdown {
    display: none;
  }
}

.mob-blurbg {
  display: none;
}

@media screen and (max-width: 900px) {
  nav {
    position: static;
    padding: 10px;
    .nav-left img {
      width: 70px;
    }
    .nav-right {
      justify-content: flex-end;
      margin-left: 25vw;
      .upper-mobile-navbar {
        display: none;
        width: 65.5vw;
        top: 0;
        height: 6.5vh;
        height: 50px;
        padding: 1.15vh 0vw;
        border-bottom: 1px solid rgba(203, 203, 203, 1);
        z-index: 5;
        > img {
          height: 45px;
        }
      }
      .upper-mobile-navbar > :last-child {
        height: 25px;
      }

      .upper-mobile-navbar.active {
        display: flex;
        justify-content: space-between;
        align-items: center;
        transition: all 0.3s ease;
        background-color: white;
        top: 0;
        right: 0;
      }
      ul {
        display: none;
      }

      .contact-btn {
        padding: 0.8vh 4vw;
        font-size: 0.9rem;
      }
      .navbar-links:hover {
        li {
          color: #0c6ed3;
        }
        .icon {
          color: #0c6ed3;
        }
      }
    }
    .nav-right li {
      padding: 3vh 0;
      > img {
        display: none;
      }
    }
    .nav-right.active {
      ul {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 0;
        width: 65.5vw;
        position: absolute;
        padding: 1vh 3vh;
        top: 0vh;
        right: 0;
        opacity: 1;
        transition: all 0.3s ease;
        z-index: 3;
        background-color: white;
        color: rgba(63, 62, 66, 1);
        gap: 0;
        margin: 0;
        border-bottom-left-radius: 0.8rem;
        div {
          margin: 0;
          padding: 0;
          li {
            font-size: 1.05rem;
            padding: 2.5vh 1vh;
          }
        }
        .icon {
          display: block;
          color: rgba(139, 139, 139, 1);
        }
      }

      .multi-dropdown {
        position: relative;
        left: 2.5vw;
        top: 0;
        padding: 0;
        box-shadow: none;
        ul {
          position: relative;
          top: 0;
          margin-top: 0;
        }
        li {
          padding: 0.5vh;
        }
      }

      .mob-navitem {
        display: flex;
      }

      .mob-navitem:hover {
        li {
          color: #0c6ed3;
        }
        .icon {
          color: #0c6ed3;
        }
      }

      .mob-navlinks {
        display: flex;
        flex-direction: column;
        border-top: 0.5px solid rgba(203, 203, 203, 1);
        align-items: flex-start;
        padding-left: 0;
        margin-top: 1vh;
        padding-bottom: 0;
        > li {
          padding: 0;
          margin: 0;
        }
      }
      .mob-navconnect {
        align-self: center;
        padding: 0 3vw;
        margin-right: 2vw;
        position: relative;
        top: 0.5vh;
        background-color: white;
        z-index: 6;
      }
      .mob-nav-mediaitem {
        // display: flex;
        border-top: 0.5px solid rgba(203, 203, 203, 1);
        padding: 4vh 4vw 5vh 2vw;
        width: 65vw;
        justify-content: space-evenly;
        margin-top: -2vh;
      }
    }
    .menu-icon {
      display: block;
    }
  }

  .mob-blurbg {
    position: fixed;
    z-index: 2;
    background-color: rgba(148, 143, 143, 0.349);
    height: 100vh;
    width: 100vw;
    top: 0;
    right: -100%;
  }
  .mob-blurbg.active {
    display: block;
    right: 0;
  }

  .multi-dropdown {
    position: relative;
    > ul {
      > div {
        gap: 0;
        > a {
          margin: 0;
          > li {
            padding: 1vh 2vw 1vh 3vw;
            margin: 0;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 375px) {
  .upper-mobile-navbar {
    width: 69.8vw;
  }
}
@media screen and (max-width: 325px) {
  .upper-mobile-navbar {
    height: 15vh;
    width: 68vw;
    > img {
      height: 10vh;
    }
  }
  .upper-mobile-navbar > :last-child {
    height: 4.5vh;
  }
}
