@import url("https://fonts.googleapis.com/css2?family=Inter&family=Lato&family=Noto+Sans&display=swap");
.web {
  position: relative;
}
.web-circle1 {
  position: absolute;
  z-index: -100;
  top: 10rem;
  width: 10rem;
}
.web-circle2 {
  position: absolute;
  z-index: -100;
  top: 47rem;
  right: 0;
  width: 15rem;
}
.web-circle3 {
  position: absolute;
  z-index: -100;
  top: 110rem;
  width: 12rem;
}
.web-circle4 {
  position: absolute;
  z-index: -100;
  top: 47rem;
  left: 0;
  width: 15rem;
  display: none;
}
#pricing-btn {
  margin: 10px 0;
  display: inline-block;
  padding: 8px 16px;
  border-radius: 35px;
  color: #ff9100;
  border: 1px solid #ff9100;
  font-weight: 600;
  font-size: 16px;
  background-color: #ff910014;
}
@media screen and (max-width: 768px) {
  #pricing-btn {
    font-size: 10px;
    margin: 12px 0 5px 0;
  }
}
.web-contact-us-button {
  width: 160px;
  height: 50px;
  border-radius: 38px;
  background: linear-gradient(
    100.33deg,
    #2549c9 6.84%,
    rgba(119, 148, 254, 0.71) 94.24%
  );
  color: white;
  margin-top: 20px;
  border: none;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.1);
  font-size: 1.2rem;
}
.web-intro {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin: 0 2% 50px 7%;
  font-family: "Inter", sans-serif;
  margin-bottom: 0;
  padding: 40px 0;
  .web-intro-content {
    width: 55%;
    .web-intro-blueText {
      color: #0c6ed3;
      font-weight: 700;
      font-size: 1.5rem;
    }
    .web-intro-content-text2 {
      font-size: 3rem;
      margin: 10px 0;
      font-weight: 700;
      span {
        color: #ff9100;
      }
    }
    .web-intro-content-text3 {
      margin: 20px 0 10px 0;
      color: #6c6c6c;
      font-weight: 500;
      font-family: "Noto Sans", sans-serif;
      font-size: 1.2rem;
    }
  }
  .web-intro-img {
    width: 50%;
    .web-img1 {
      width: 100%;
      margin: 0 auto;
      float: right;
    }
  }
}
.web-underline {
  text-align: center;
  line-height: 15px;
}
.web-top-custom {
  text-align: center;
  font-family: "Noto Sans", sans-serif;
}
.web-top-heading {
  text-align: center;
  font-family: "Noto Sans", sans-serif;
  font-size: 1.2rem;
  font-weight: 600;
  color: #0c6ed3;
  padding: 15px 0px 10px;
}
.web-container {
  margin: 0 3%;
  .web-design {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin: 0 3% 90px;
    font-family: "Inter", sans-serif;
    .web-top-design-heading {
      font-family: "Noto Sans", sans-serif;
      font-size: 1.2rem;
      font-weight: 600;
      color: #0c6ed3;
      padding: 15px 0px 0px;
    }
    .web-design-underline {
      line-height: 5px;
      margin-top: -10px;
    }
    .web-design-content {
      width: 60%;
      padding-left: 40px;
      .web-design-content-custom {
        margin: 10px 0;
      }
      strong {
        font-size: 1.8rem;
        font-family: "Noto Sans", sans-serif;
      }
      .web-design-content-description {
        margin: 20px 0 10px 0;
        color: #6c6c6c;
        font-weight: 500;
        font-family: "Noto Sans", sans-serif;
        font-size: 1.125rem;
      }
      .web-design-icons {
        display: flex;
        justify-content: center;
        margin-top: 20px;
        align-items: center;
        width: fit-content;
        height: 50px;
        gap: 10px;
        .web-design-icon {
          justify-content: center;
          width: 35px;
          display: flex;
          align-items: center;
          box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.1);
          border-radius: 5px;
          padding: 5px;
          height: 35px;
          img {
            width: 30px;
          }
        }
      }
    }
    .web-design-imgs {
      width: 50%;
      .web-design-img {
        width: 90%;
        margin: 0 auto;
      }
    }
  }
}
.web-top-custom strong {
  font-size: 1.8rem;
}
.web-boxes {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 30px 0;
  column-gap: 15px;
}
.web-category-box {
  width: 270px;
  box-shadow: 0px 2px 59px 0px #00000021;
  border-radius: 10px;
  margin: 0.5rem 0px;
  background-color: #fff;
  box-sizing: border-box;
  padding: 10px 5px;
  position: relative;
  display: flex;
  align-items: center;
  transition: background-color;
  .web-category-box-text {
    color: #0c6ed3;
    font-size: 1.1rem;
    font-weight: 500;
    margin-left: 5px;
    font-family: "Noto Sans", sans-serif;
  }
}
.web-category-box:hover {
  background-color: #0c6ed3;
  transition-duration: 0.3s;
  .web-category-box-text {
    color: #fff;
  }
}
.web-benifits {
  font-family: "Noto Sans", sans-serif;
  margin: 70px 0;
  .web-boxes {
    margin: 30px 0;
  }
  .web-benifits-box {
    width: 270px;
    box-shadow: 0px 4px 67px 6px #0000000a;
    border-radius: 10px;
    margin: 0.5rem 0px;
    box-sizing: border-box;
    padding: 10px 5px;
    padding-bottom: 5px;
    position: relative;
    text-align: left;
    background-color: white;
    overflow: hidden;
    z-index: 1;
    .web-benifits-box-top {
      display: flex;
      align-items: center;
      .web-benifits-box-top-heading {
        font-weight: 600;
        font-size: 1rem;
        margin-left: 10px;
      }
    }
    .web-benifits-box-content {
      color: #4b4b4b;
      font-size: 0.75rem;
      padding: 0 0rem 0.8rem 0.8rem;
    }
  }
  .web-benifits-box::before {
    content: "";
    position: absolute;
    background: #b4d2f1;
    right: -50px;
    bottom: -50px;
    width: 100px;
    height: 100px;
    border-radius: 50px;
    transition: all 0.3s;
    z-index: -1;
  }
  .web-benifits-box:hover::before {
    background: #0b64c0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    border-radius: 0px;
  }
  .web-benifits-box:hover .web-benifits-box-top .web-benifits-box-top-heading,
  .web-benifits-box:hover .web-benifits-box-content {
    color: #ffffff;
  }
}
.web-solutions-box {
  width: 250px;
  background: #ffffff;
  border-radius: 10px;
  margin: 0.5rem 0px;
  text-align: center;
  padding: 15px;
  border: 1px solid rgba(174, 172, 172, 0.705);
  border-top: 0px solid;
  border-bottom: 0;
  box-shadow: -1px 1px 1px 0px rgba(0, 0, 0, 0.2509803922);
  .web-solutions-box-img {
    background-color: #8fbceb;
    margin: 0 auto;
    width: 70px;
    height: 70px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .web-solutions-box-heading {
    text-align: center;
    margin: 10px 0;
    font-family: "Noto Sans", sans-serif;
    font-size: 1.2rem;
    font-weight: 600;
    color: #0c6ed3;
    padding: 15px 0px 10px;
  }
  .web-solutions-box-content {
    color: #3a3a3a;
    font-family: "Lato", sans-serif;
    margin: 10px 0 20px 0;
    line-height: 25px;
    font-size: 1.1rem;
  }
}
.web-samples {
  margin: 70px 0 130px 0;
}
.web-sample-box {
  text-align: center;
  .web-sample-box-img {
    width: 365px;
    height: 330px;
    display: flex;
    align-items: center;
    justify-content: end;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.1882352941);
    img {
      width: 93%;
    }
  }
  .web-sample-box-content1 {
    color: #0c6ed3;
    font-family: "Lato", sans-serif;
    font-size: 18px;
    margin-top: 20px;
    font-weight: 600;
  }
  .web-sample-box-content2 {
    font-family: "Noto Sans", sans-serif;
    color: #222e50;
    font-size: 22px;
    font-weight: 600;
    line-height: 40px;
  }
}
@media screen and (min-width: 600px) and (max-width: 900px) {
  .web-intro {
    flex-direction: column-reverse;
    padding: 0;
    .web-intro-content {
      width: 90%;
      .web-intro-content-text2 {
        font-size: 2.5rem;
      }
    }
    .web-intro-img {
      width: 80%;
      margin-right: 5%;
    }
  }
  .web-container .web-design {
    flex-direction: column-reverse;
    margin-bottom: 30px;
    .web-design-imgs {
      width: 65%;
      margin-top: 20px;
      .web-design-img {
        width: 100%;
      }
    }
    .web-design-content {
      width: 90%;
      margin-top: 30px;
    }
  }
}
@media screen and (max-width: 600px) {
  .web-contact-us-button {
    width: 120px;
    height: 40px;
    font-size: 0.8rem;
  }
  .web-intro {
    margin: 0 5%;
    flex-direction: column-reverse;
    padding: 0;
    .web-intro-img {
      width: 100%;
      .web-img1 {
        width: 100%;
        float: none;
      }
    }
    .web-intro-content {
      width: 100%;
      .web-intro-content-text1 {
        font-size: 0.95rem;
        padding: 0;
        margin-bottom: 10px;
      }
      .web-intro-content-text2 {
        font-size: 1.5rem;
        margin: 0;
      }
      .web-intro-content-text3 {
        margin: 10px 0;
        font-size: 0.8rem;
      }
    }
  }
  .web-top-custom strong {
    font-size: 1.1rem;
  }
  .web-top-heading {
    font-size: 0.9rem;
    padding: 0;
  }

  .web-container {
    .web-design {
      flex-direction: column-reverse;
      .web-design-imgs {
        width: 100%;
        text-align: center;
        margin-top: 30px;
        .web-design-img {
          width: 100%;
        }
      }
      .web-design-content {
        width: 100%;
        padding-left: 0;
        .web-top-design-heading {
          font-size: 0.9rem;
          margin-top: 20px;
        }
        .web-design-content-description {
          margin: 10px 0;
          font-size: 0.8rem;
        }
        .web-design-icons {
          gap: 8px;
          margin-top: 0;
          height: fit-content;
          .web-design-icon {
            width: 28px;
            padding: 0;
            height: 28px;
            img {
              width: 22px;
            }
          }
        }
        strong {
          font-size: 1.1rem;
        }
      }
    }
    .web-benifits {
      .web-boxes {
        margin: 0;
        margin-top: 30px;
        column-gap: 5px;
        padding: 0;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        overflow: hidden;
        img {
          width: 40px;
        }
      }
      .web-benifits-box {
        width: 165px;
        padding: 0.8rem 0.5rem;
        padding-bottom: 5px;
        .web-benifits-box-top {
          flex-direction: column;
          .web-benifits-box-top-heading {
            font-size: 0.7rem;
            text-align: center;
            span {
              display: inline;
            }
          }
        }
      }
      .web-benifits-box::before {
        right: -30px;
        bottom: -30px;
        width: 70px;
        height: 70px;
      }
      .web-benifits-box-content {
        display: none;
      }
    }
    .web-category {
      .web-boxes {
        margin: 30px 0;
        column-gap: 5px;
        padding: 0;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        overflow: hidden;
        .web-category-box {
          width: 165px;
          padding: 6px 3px;
          .web-category-box-img img {
            width: 38px;
          }
          .web-category-box-text {
            font-size: 12px;
            margin-left: 0;
          }
        }
      }
    }
  }
  .web-solutions {
    .web-boxes {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      grid-gap: 10px;
      overflow: hidden;
      margin: 30px 0;
      .web-solutions-box {
        width: 150px;
        padding: 15px 5px;
        .web-solutions-box-img {
          width: 45px;
          height: 45px;
          img {
            width: 30px;
            height: 30px;
          }
        }
        .web-solutions-box-heading {
          font-size: 0.9rem;
          margin: 0;
        }
        .web-solutions-box-content {
          font-size: 0.7rem;
          margin: 0;
          line-height: 17px;
        }
      }
    }
  }
  .web-samples .web-sample-box {
    margin-top: 20px;
    .web-sample-box-img {
      width: 280px;
      height: 250px;
      img {
        width: 90%;
      }
    }
    .web-sample-box-content2 {
      font-size: 16px;
    }
    .web-sample-box-content1 {
      font-size: 12px;
    }
  }
  .web-circle1 {
    top: 25rem;
    width: 5rem;
  }
  .web-circle2 {
    top: 40rem;
    width: 8rem;
  }
  .web-circle3 {
    top: 110rem;
    width: 5rem;
  }
  .web-circle4 {
    display: block;
    top: 76rem;
    width: 8rem;
    rotate: (180deg);
  }
}
