.payment .form-div form .formHeading {
  align-self: center !important;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 30px;
}
.payment .form-div form button {
  align-self: unset !important;
}
.payment .form-div {
  position: relative;
  margin-bottom: 100px;
}
.payment .form-image {
  position: absolute;
  width: 65%;
  right: -51%;
  bottom: -23%;
}
.payment .form-image img {
  width: 100%;
}
.payment .form-div form .form-box .last-name {
  display: block;
  align-self: end;
  position: relative;
  width: 47%;
}
.payment .form-div form .form-box .last-name .box-A {
  width: 100% !important;
  padding: 15px 45px;
}
.payment .form-div form .form-box .last-name img {
  left: 9% !important;
}
.payment .form-div form .formHeading p {
  font-size: 1.8rem !important;
  text-align: center;
}
.payment .form-div form .formHeading h3 {
  font-size: 1.25rem !important;
}
.payment-form .main-img {
  background-image: url("../../assets/images/contact-page/hero.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.payment-form .main-imgText {
  font-family: "inter";
  font-weight: 700;
  color: #fff;
  font-size: 2.8rem;
  text-align: center;
  margin: 0 30px;
}
.payment-form .pay-circle {
  position: absolute;
  left: 0;
  bottom: 7rem;
  width: 9rem;
  z-index: -1;
}
.payment .circle2 {
  width: 11rem !important;
}
.payment-form .contactInfoBtn {
  font-size: 1.25rem;
  width: unset !important;
  height: unset !important;
  padding: 15px 40px 15px 40px !important;
}
.payment .form-div form {
  position: relative !important;
  z-index: 1 !important;
}
.payment .form-image {
  position: relative !important;
  z-index: -1 !important;
}
.payment .form-div form button {
  margin-top: 20px;
}
@media only screen and (max-width: 780px) {
  .payment {
    margin-top: 30px !important;
  }
  .payment {
    margin-top: 20px !important ;
  }
  .payment .form-image {
    right: 0;
    top: 18%;
  }
  .payment-form .pay-circle {
    width: 6rem;
    bottom: 12rem;
  }
  .payment .form-div form .formHeading p {
    font-size: 1.6rem !important;
  }
  .payment-form .contactInfoBtn {
    font-size: 1rem;
  }
  .payment .form-div {
    margin-bottom: 60px;
  }
  .payment-form .main-img {
    height: 150px;
  }
  .payment-form .main-imgText {
    font-size: 1.3rem;
  }
}
