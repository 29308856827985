@import "./global/global.scss";
@import "./components/About/About.scss";
@import "./components/Footer/footer.scss";
@import "./Pages/Solutions/CMS/cms.scss";
@import "./Pages/Home/home.scss";
@import "./components/Contact/contact.scss";
@import "./Pages/Payment/index.scss";

@import "./components/CRM/crm.scss";

@import "./Pages/Services/WebDev/index.scss";
@import "./Pages/Services/AppDev/index.scss";
@import "./Pages/Services/Seo/index.scss";
@import "./Pages/Services/UI/";

@import "./Pages/Solutions/HRM/index.scss";
@import "./Pages/Solutions/HMS/index.scss";
@import "./Pages/Solutions/LMS/index.scss";
@import "./Pages/Solutions/SMS/index.scss";
@import "./Pages/Internship/intern.scss";

@import "./Pages/Legalinfo/PrivacyPolicy/index.scss";
.stu-carousel {
  margin: 0 100px;
}
.stu-carousel img {
  width: 100%;
  object-fit: contain;
  padding-bottom: 40px;
}
@media screen and (max-width: 768px) {
  .stu-carousel {
    margin: 0 10px 20px 0;
  }
  .stu-carousel .swiper-button-prev:after,
  .stu-carousel .swiper-button-next:after {
    font-size: 15px !important;
  }
  .stu-carousel .swiper-button-next,
  .stu-carousel .swiper-button-prev {
    width: 20px;
    height: 20px;
    padding: 5px !important;
  }
}
.stu-carousel .swiper-button-prev:after,
.stu-carousel .swiper-button-next:after {
  font-size: 30px;
  color: white;
}
.stu-carousel .swiper-button-next,
.stu-carousel .swiper-button-prev {
  background-color: #0c6ed3;
  opacity: 0.7;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  padding: 10px;
  box-shadow: 0px -1px 3px rgb(187, 179, 179);
}
