.privacy-image{
  height: 55vh;
  width: 100%;  
}
// #privacy-container{
    // margin-bottom: 90px;
// }

.privacy-container-desc .left h1{
    color: var(--mail-color, #EA4335);
       
        font-family: Noto Sans;
        font-size: 30px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        margin-bottom: 20px;
        margin-top: 50px;
}

.privacy-container-desc{
 padding-left: 30px;
 padding-right: 30px;   
}
.privacy-container-desc .left .privacy-btn1{
    color: var(--pc-white, #FFF);
        text-align: center;
        font-family: Noto Sans;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        display: flex;
        padding: 15px 30px;
        background-color: #222E50;
        border-radius: 30px;
        gap: 10px;
        border: none;
        
        width: 230px;
}
.privacy-container-desc .left .privacy-btn2{
    color: var(--blue-c, #222E50);
    background-color: #FFF;
        text-align: center;
        font-family: Noto Sans;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        display: flex;
        padding: 15px 20px;
        border: 2px solid #222E50;
        border-radius: 30px;
        gap: 10px;
       
        width: 230px;
}
.privacy-container-desc .left .privacy-btn3{
    color: var(--blue-c, #222E50);
        background-color: #FFF;
        text-align: center;
        font-family: Noto Sans;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        display: flex;
        padding: 15px 20px;
        border: 2px solid #222E50;
        border-radius: 30px;
        gap: 10px;
    
        width: 230px;

}
.privacy-container-desc .button_center{
  display: flex;
  flex-direction: column; 
  gap: 16px;  
 }
.privacy-container-desc .right h1{
    color: var(--blue-blue-600, #0B64C0);
        text-align: center;
        font-family: Inter;
        font-size: 25px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: 0.375px;
        margin-bottom: 20px;
}

 .privacy-container-desc .left{
    flex: 0.3;
}
.privacy-container-desc .right{
    flex: 0.7;
}

.privacy-container-desc{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-left: 100px;
    padding-right: 100px;
    padding-top: 50px;
    padding-bottom: 80px;
   
    
}
.privacy-container-desc .right .para{
   font-size: 20px;
   padding: 5px 0px; 
}
.privacy-container-desc .right .heading{
  font-size: 20px; 
  color: black; 
  text-align: start;
  padding-top: 10px;
  padding-bottom: 10px;
  margin: 0px;
  
}
.privacy-container-desc li{
list-style-type: disc;
font-size: 20px;
}

.privacy-container-desc ul{
    padding-left: 40px;
}


.privacy-container-desc .right a{
color: #000;
    font-family: Noto Sans;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
}
.privacy-container-desc .semicircle{
    position: absolute;
        top: 368px;
        left: -7px;
        z-index: -1;
}
.privacy-container-desc .right .rombhus{
     
            position: absolute;
            right: -95px;
            z-index: -1;
} 
.privacy-container-desc .right .semicircle1{
    position: absolute;
    left: 0px;
    bottom: -2500px;
    z-index: -1;
}





@media screen and (max-width: 679px) {
.privacy-container-desc{
   flex-direction: column; 
   padding: 20px;
}
.privacy-image{
height: 230px;
}
.privacy-container-desc .right .heading{
    font-size: 15px;
}

.privacy-container-desc .left h1{
    margin-top: 15px;
    margin-bottom: 15px;
}
.button_center{
  padding-bottom: 20px;  
}
.privacy-container-desc ul{
    padding: 20px;
}

.privacy-container-desc .left .privacy-btn1 , .privacy-container-desc .left .privacy-btn2, .privacy-container-desc .left .privacy-btn3{
    padding-top: 10px;
    padding-bottom: 10px;
    
}

.privacy-container-desc .right .para{
    font-size: 15px;
}
.privacy-container-desc .semicircle{
    width: 150px;
        top: 124px;
}
.privacy-container-desc .semicircle1{
    width: 100px;
}
.privacy-container-desc .right .rombhus{
    width: 180px;
}
.privacy-container-desc .right a{
    font-size: 15px;
}
.privacy-container-desc li{
    font-size: 15px;
}





}