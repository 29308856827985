.footer {
  .foot1 {
    font-family: $fontFamily;
    background-color: #052e59;
    display: flex;
    padding: 20px 5px;
    justify-content: space-around;
    .footNav {
      display: flex;
      flex-direction: column;
      color: #ffffff;
      align-items: flex-start;
      justify-content: flex-start;
      width: 21%;
      .footSocial {
        padding-top: 1.2rem;
      }
      .footHead {
        font-size: 1.7rem;
        font-weight: 600;
        padding-bottom: 0.4rem;
      }
      .footItem {
        display: flex;
        align-items: flex-start;
        padding: 0.4rem 0;
        color: #ffffff;
        font-size: 0.9rem;
      }
    }
    .footDisc {
      width: 27%;
      align-items: flex-start;
      justify-content: flex-start;
    }
    .officialInfo {
      img {
        padding-right: 0.8rem;
      }
    }
    p {
      color: #ffffff;
      padding: 2rem 0.5rem;
    }
  }
  .foot2 {
    color: #ffffff;
    background: #032549;
    text-align: center;
    line-height: 2rem;
  }
}
@media only screen and (max-width: 900px) {
  .footer {
    .foot1 {
      flex-wrap: wrap;
      .footNav {
        width: 46%;
        margin-top: 1.5rem;
        // img{
        //    width: 70%;
        // }
        .footHead {
          font-size: calc(17px + 0.390625vw);
        }
        .footItem {
          padding: 0.6rem 0;
          font-size: calc(14px + 0.390625vw);
        }
      }
      .footDisc,
      .officialInfo {
        width: 89%;
      }
      .footDisc {
        p {
          width: 90%;
          text-align: center;
          line-height: 1.5rem;
        }
        align-items: center;
        img {
          width: 55%;
        }
      }
      .officialInfo {
        .footSocial {
          img {
            padding-right: 2.4rem;
          }
        }
      }
    }
  }
  .foot2 {
    font-size: calc(14px + 0.390625vw);
    padding: 0.7rem 0;
  }
}
@media only screen and (max-width: 500px) {
}
