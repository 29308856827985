@import url("https://fonts.googleapis.com/css2?family=Geologica:wght@100..900&display=swap");
.intern {
  margin-top: 100px;
  position: relative;
}
.intern-top {
  padding: 6px 12px;
  background-color: #9cc4ff1c;
  border: 1px solid;
  border-color: linear-gradient(
    91.26deg,
    #425e91 2.9%,
    #2c44c6 49.98%,
    #2ecc51 73.53%,
    #cb2828 97.07%
  );
  width: fit-content;
  border-radius: 24px;
}
.intern-top span {
  border-radius: 24px;
  background-color: #fff;
  box-shadow: 0px 0px 2px 0px #00000014;
  padding: 4px 8px;
}
.intern-headingContainer {
  display: flex;
  gap: 24px;
  align-items: center;
  flex-direction: column;
}
.intern-heading {
  color: #060606;
  font-family: "Noto Sans", sans-serif;
  text-align: center;
  font-size: 2.2rem;
}
.intern-headingContainer p {
  color: #5c5c5c;
  text-align: center;
  font-family: "Lato", sans-serif;
  font-size: 1.2rem;
  font-weight: 600;
}
.intern-btn0,
.intern-btn {
  padding: 8px 18px;
  border: 2px solid #425e91;
  border-radius: 36px;
  color: #425e91;
  font-size: 1.1rem;
  font-family: "Noto Sans", sans-serif;
}
.intern-btn {
  color: #fff;
  background-color: #425e91;
}
.intern-showcase {
  margin: 0 10%;
  // gap: 30px;
  display: grid;
  // flex-direction: column;
  align-items: end;
  position: relative;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 25px;
  grid-template-areas:
    "showcase1 showcase2 showcase3 showcase4"
    "showcase5 showcase6 showcase7 showcase7";
}
.showcase1 {
  margin-bottom: 60px;
  grid-area: showcase1;
}
.showcase2 {
  margin-bottom: -20px;
  grid-area: showcase2;
}
.showcase3 {
  grid-area: showcase3;
}
.showcase4 {
  grid-area: showcase4;
}
.showcase5 {
  grid-area: showcase5;
}
.showcase6 {
  grid-area: showcase6;
}
.showcase7 {
  grid-area: showcase7;
}
.intern .circle {
  position: absolute;
  top: -45px;
  z-index: -1;
  right: -79px;
  // z-index: 1;
}
.intern .rhombus {
  position: absolute;
  left: 0;
  z-index: -1;
  bottom: 40rem;
}
// .intern-showcase-row{
//     display: flex;
//     gap: 30px;
//     justify-content: center;
//     align-items: flex-end;
//     width: 100%;
// }
.intern-showcase::before {
  content: "";
  position: absolute;
  height: 30%;
  background: rgb(255, 255, 255);
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0.7847514005602241) 53%,
    rgba(255, 255, 255, 0) 100%
  );

  width: 110%;
  z-index: 9;
  bottom: -2px;
}
.intern-showcase img {
  width: 100%;
  margin-top: -35px;
}
.intern-features {
  margin: 20px 6% 100px 6%;
  gap: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;

  .intern-features-box {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    flex-direction: column;
    width: 23.5%;
    padding: 15px;
    min-width: 270px;
    text-align: center;
    gap: 18px;
  }
  .intern-features-box:hover {
    box-shadow: 0px 2px 79px 0px #00000014;
  }
  .intern-features-logo {
    width: 72px;
    height: 72px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: #0c6ed3;
    color: #fff;
    font-size: 1.8rem;
  }
  .intern-features-heading {
    color: #18191c;
    font-weight: 500;
    font-size: 18px;
    text-transform: uppercase;
    font-family: "Be Vietnam Pro", sans-serif;
  }
  .intern-features-box p {
    color: #767f8c;
    font-size: 14px;
    font-family: "Be Vietnam Pro", sans-serif;
    font-weight: 400;
  }
}
.intern-training {
  margin: 60px 0;
  .underline {
    margin-bottom: 50px;
  }
  .intern-training-boxes {
    display: flex;
    justify-content: center;
    gap: 30px;
    flex-wrap: wrap;
    margin: 0 6%;
  }
  .intern-training-box {
    width: 28%;
    border: 0.62px solid #0000001a;
    padding: 15px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap: 13px;
    min-width: 330px;
  }
  .intern-training-box-img img {
    width: 100%;
  }
  .intern-training-box-bottom {
    display: flex;
    justify-content: space-between;
    padding: 15px;
  }
  .intern-training-box-teachers {
    display: flex;
    max-width: 145px;
  }
  .teachers {
    border: 2px solid #ffffff;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    margin-left: -15%;
    overflow: hidden;
    box-shadow: 0px 0px 12px 0px #00000029;
  }
  .teachers img {
    width: 100%;
  }
  .intern-training-box-btn {
    padding: 16px 18px;
    background: #0b64bf29;
    border-radius: 8px;
    color: #0b64bf;
    min-width: 103px;
    border: none;
    font-weight: 500;
    display: block;
    font-family: "Noto Sans", sans-serif;
  }
  .intern-training-box-heading {
    color: #262626;
    font-size: 1.125rem;
    font-weight: 600;
    font-family: "Noto Sans", sans-serif;
  }
  .intern-training-box-duration {
    color: #4d4d4d;
  }
}
.intern-review {
  background-color: #d7eaff;
  margin: 60px 0;

  .intern-review-heading {
    font-family: "Geologica", sans-serif;
    color: #3f3f3f;
    font-weight: 700;
    position: relative;
  }
  .intern-review-desc {
    font-family: "Geologica", sans-serif;
    color: #909090;
    position: relative;
    text-align: justify;
  }
  .intern-review-btn {
    background-color: #0c6ed3;
    border-radius: 36px;
    padding: 10px 15px;
    color: #fdfdfd;
    font-family: "Noto Sans", sans-serif;
    border: none;
  }
  .commatop {
    position: absolute;
    z-index: 99;
    top: -30px;
    left: -50px;
  }
  .commabottom {
    position: absolute;
    z-index: 99;
    bottom: -30px;
    right: 4%;
  }
  .intern-review-right {
    font-family: "Geologica", sans-serif;
    background-color: #fcfcfc;
    border-radius: 16px;
    padding: 30px 20px 15px 20px;
    position: relative;
    color: #909090;
    margin: 0 10%;
    flex: 5;
    max-width: 350px;
    display: flex;
    align-items: center;
    gap: 15px;
    flex-direction: column;
    .intern-review-desc {
      align-items: center;
    }
  }
  .intern-review-left {
    flex: 7;
    padding-left: 8%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
  }
  .intern-review-swiper {
    display: flex;
    // flex-wrap: wrap;
    margin-top: 40px;
    align-items: center;
    justify-content: space-between;
    padding: 40px;
    padding-bottom: 70px;
  }
  .review-stars {
    display: flex;
    align-items: center;
    gap: 5px;
    justify-content: center;
  }
  .greyStars {
    font-size: 15px;
    color: #909090;
  }
  .blueStar {
    color: #0075ff;
  }
  .intern-review-right img {
    position: absolute;
    width: 48px;
    top: -24px;
    left: calc(50% - 24px);
    z-index: 99;
  }
}
.intern-review .swiper-button-next:after {
  content: none !important;
}
.intern-review .swiper-button-prev:after {
  content: none !important;
}
.intern-review .swiper-button-next,
.intern-review .swiper-rtl .intern-review .swiper-button-prev,
.intern-review .swiper-button-prev,
.intern-review .swiper-rtl .intern-review .swiper-button-next {
  width: 50px !important;
  height: 50px !important;
  background: #fff;
  border-radius: 50%;
}
.intern .icon {
  width: 80%;
  color: #444444;
}
.intern-review .swiper-button-next {
  left: 14% !important;
  top: unset !important;
  bottom: 5px !important;
  margin-left: 10px !important;
}
.intern-review .swiper-button-prev {
  left: 10% !important;
  top: unset !important;
  bottom: 5px !important;
}
@media screen and (max-width: 900px) {
  .intern-review-swiper {
    flex-direction: column;
  }
}
.intern-review-left {
  margin-bottom: 50px;
}
.showcase2 {
  margin-bottom: -15px;
}
.showcase1 {
  margin-bottom: 25px;
}
.intern-showcase img {
  margin-top: -10px;
}
.intern-review .swiper-button-next {
  left: 16% !important;
  // top: 320px !important;
  bottom: 40px !important;
}
.intern-review .swiper-button-prev {
  left: 10% !important;
  // top: 320px !important;
  bottom: 40px !important;
}

@media screen and (max-width: 600px) {
  .intern {
    margin-top: 70px;
  }
  .intern-top {
    font-size: 10px;
  }
  .intern-headingContainer {
    margin: 0 15px;
    gap: 16px;
  }
  .intern-heading {
    font-size: 1.15rem;
  }
  .intern-headingContainer p {
    font-size: 0.7rem;
  }
  .intern-btn0,
  .intern-btn {
    font-size: 0.8rem;
  }
  .intern-showcase {
    margin: 0;
    column-gap: 8px;
  }
  .showcase1 {
    margin-bottom: 20px;
  }
  .showcase2 {
    margin-bottom: -5px;
  }
  .intern-showcase::before {
    width: 100%;
  }
  .intern-features .intern-features-box {
    flex-wrap: wrap;
    width: 23.5%;
    padding: 6px;
    min-width: 160px;
    text-align: center;
    gap: 13px;
  }
  .intern-features .intern-features-logo {
    width: 40px;
    height: 40px;
    font-size: 1rem;
  }
  .intern-showcase img {
    margin-top: -10px;
  }
  .intern-features {
    margin: 20px 2.5% 45px 2.5%;
    gap: 12px;
  }
  .intern-features .intern-features-heading {
    font-size: 11px;
  }
  .intern-features .intern-features-box p {
    font-size: 8px;
  }
  .intern {
    .home-service-heading {
      font-size: 12px;
    }
    strong {
      font-size: 18px;
    }
    .intern-training .underline {
      margin-bottom: 30px;
    }
    .intern-training {
      margin: 45px 0;
    }
  }
  .intern-training .intern-training-box {
    min-width: 290px;
    width: 100%;
    gap: 9px;
    padding: 12px;
  }
  .intern-training .intern-training-box-heading {
    font-size: 1rem;
  }
  .intern-training .intern-training-box-duration {
    font-size: 14px;
  }
  .intern-training .intern-training-box-btn {
    font-size: 14px;
    padding: 10px 18px;
    min-width: 96px;
  }
  .intern-training .teachers {
    width: 42px;
    height: 42px;
  }
  .intern-review .intern-review-heading {
    font-size: 1.125rem;
  }
  .intern-review .intern-review-desc {
    font-size: 12px;
  }
  .intern-review .intern-review-desc {
    margin: 0 2%;
  }
  .commatop,
  .commabottom {
    width: 35px;
  }
  .intern-review .commatop {
    top: -37px;
    left: 0px;
  }
  .commatop img,
  .commabottom img {
    width: 100%;
  }
  .intern-review .intern-review-swiper {
    padding: 25px;
  }
  .intern-review .intern-review-left {
    padding-left: 0;
  }
  .intern-review .commabottom {
    bottom: -30px;
    right: 0%;
  }
  .intern .circle {
    display: none;
  }
  .intern .rhombus {
    display: none;
  }
  .intern-review .swiper-button-next {
    left: 14% !important;
    top: 300px !important;
  }
  .intern-review .swiper-button-prev {
    top: 225px !important;
  }
  .intern-review .swiper-button-next {
    left: 19% !important;
    top: 330px !important;
    bottom: unset !important;
  }
  .intern-review .swiper-button-prev {
    left: 8% !important;
    top: 330px !important;
    bottom: unset !important;
  }
  .intern-review .swiper-button-next,
  .intern-review .swiper-rtl .intern-review .swiper-button-prev,
  .intern-review .swiper-button-prev,
  .intern-review .swiper-rtl .intern-review .swiper-button-next {
    width: 32px !important;
    height: 32px !important;
  }
  .intern-review-left {
    margin-bottom: 70px;
  }
}
// @media (max-width: 767px) {
//   .intern-review .intern-review .swiper-button-next,
//   .intern-review .intern-review .swiper-button-prev {
//     display: none;
//   }
// }
