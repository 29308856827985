@import url("https://fonts.googleapis.com/css2?family=Inter&family=Lato&family=Noto+Sans&display=swap");
.hms-main-img {
  position: relative;
  img{
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 100%;
    position: relative;
    }
    .circle1 {
      position: absolute;
      z-index: -100;
      bottom: -4rem;
      width: 150px;
      left: 0;
    }
}
.underline {
  text-align: center;
  line-height: 5px;
}
.hms-intro-content-text1 {
  font-family: "Noto Sans", sans-serif;
  font-size: 1.5rem;
  font-weight: 700;
  color: #0c6ed3;
}
.hms-top-heading {
  text-align: center;
  font-family: "Noto Sans", sans-serif;
  font-size: 1.3rem;
  font-weight: 600;
  color: #0c6ed3;
  padding: 15px 0px 10px;
}
.hms-top-custom {
  text-align: center;
}
strong {
  font-size: 1.7rem;
  font-weight: 600;
}
.hms-container {
  font-family: "Noto Sans", sans-serif;
  position: relative;
  .circle2 {
    position: absolute;
    z-index: -1;
    top: 60rem;
    width: 12rem;
  }
  .rectangle2 {
    position: absolute;
    z-index: -1;
    width: 12rem;
    right: 0;
    top: 20rem;
  }
}
.hms-intro {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 50px 3% 50px 5%;
  font-family: "Inter", sans-serif;
  .hms-intro-content {
    width: 59%;
    .hms-intro-content-text2 {
      font-size: 1.8rem;
      font-weight: 700;
      span {
        color: #ff9100;
      }
    }
    .hms-intro-content-text3 {
      margin: 20px 0 10px 0;
      color: #6c6c6c;
      font-size: 1.3rem;
      font-weight: 500;
      font-family: "Noto Sans", sans-serif;
    }
    .hms-contact-us-button {
      width: 160px;
      height: 50px;
      border-radius: 38px;
      background: linear-gradient(
        100.33deg,
        #2549c9 6.84%,
        rgba(119, 148, 254, 0.71) 94.24%
      );
      color: white;
      margin-top: 20px;
      border: none;
      box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.1);
      font-size: 1.2rem;
    }
  }
  .hms-intro-img {
    width: 38%;
    .hms-img1 {
      width: 100%;
      margin: 0 auto;
      float: right;
    }
  }
}
.hms-boxes {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 50px 3%;
  column-gap: 8px;
}
.hms-service .hms-boxes {
  margin: 50px 3%;
}
.hms-service-box {
  width: 350px;
  background: #ffffff;
  box-shadow: 0px 4px 67px 6px #0000000a;
  border-radius: 10px;
  margin: 0.5rem 0px;
  text-align: center;
  box-sizing: border-box;
  padding: 10px;
  transition: all 0.3s;
  .hms-service-box-heading {
    font-weight: 600;
    font-size: 1.3rem;
    margin-top: 0.8rem;
  }
  .hms-service-box-content {
    padding: 1rem;
    color: #4b4b4b;
    font-size: 1.125rem;
    font-weight: 400;
  }
}
.hms-service-box:hover {
  background: #0b64c0;
  .hms-service-box-heading {
    color: #ffffff;
  }
  .hms-service-box-content {
    color: #ffffff;
  }
}
.hms-benifits {
  font-family: "Noto Sans", sans-serif;
  margin: 50px 0;
  .hms-boxes{
    margin: 30px 3%;
    justify-content: center;
  }
  .hms-benifits-box {
    width: 270px;
    box-shadow: 0px 4px 67px 6px #0000000a;
    border-radius: 10px;
    margin: 0.5rem 0px;
    box-sizing: border-box;
    padding: 10px 5px;
    padding-bottom: 5px;
    position: relative;
    text-align: left;
    background-color: white;
    overflow: hidden;
    z-index: 1;
    .hms-benifits-box-top {
      display: flex;
      align-items: center;
      .hms-benifits-box-top-heading {
        font-weight: 600;
        font-size: 1rem;
        span{
          display:block;
        }
      }
    }
    .hms-benifits-box-content {
      color: #4b4b4b;
      font-size: 0.75rem;
      padding: 0.1rem 0rem 0.6rem 0.8rem;
    }
  }
  .hms-benifits-box::before {
    content: "";
    position: absolute;
    background: #b4d2f1;
    right: -50px;
    bottom: -50px;
    width: 100px;
    height: 100px;
    border-radius: 50px;
    transition: all 0.3s;
    z-index: -1;
  }
  .hms-benifits-box:hover::before {
    background: #0b64c0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    border-radius: 0px;
  }
  .hms-benifits-box:hover .hms-benifits-box-top .hms-benifits-box-top-heading,
  .hms-benifits-box:hover .hms-benifits-box-content {
    color: #ffffff;
  }
}
.hms-solutions {
  margin-bottom: 70px;
  .hms-solutions-box {
    width: 250px;
    background: #ffffff;
    border-radius: 10px;
    margin: 0.5rem 0px;
    text-align: center;
    padding: 15px;
    border: 1px solid rgba(174, 172, 172, 0.705);
    border-top: 0px solid;
    border-bottom: 0;
    box-shadow: -1px 1px 1px 0px rgba(0, 0, 0, 0.2509803922);
  }
  .hms-solutions-box-img {
    background-color: #8fbceb;
    margin: 0 auto;
    width: 70px;
    height: 70px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.hms-solutions-box-heading {
  text-align: center;
  margin: 10px 0;
  font-family: "Noto Sans", sans-serif;
  font-size: 1.2rem;
  font-weight: 600;
  color: #0c6ed3;
  padding: 15px 0px 10px;
}
.hms-solutions-box-content {
  color: #3a3a3a;
  font-family: "Lato", sans-serif;
  margin: 10px 0 20px 0;
  line-height: 25px;
  font-size: 1.1rem;
}

//Responsive
@media screen and (min-width:600px) and (max-width:900px){
 
  .hms-intro{
    flex-direction: column;
    .hms-intro-content{
      width: 90%;
      .hms-intro-content-text2 {
        font-size: 2.5rem;
    }
    }
    .hms-intro-img {
      width: 80%;
      margin-right: 10%;
      .hms-img1{
        width: 85%;
      }
  }
  }
  .hrm-boxes{
    padding: 0;
  }
}
@media screen and (max-width: 600px) {
  .hms-intro {
    .hms-intro-content .hms-contact-us-button {
      width: 120px;
      height: 40px;
      font-size: 0.8rem;
    }
    margin: 30px 5%;
    z-index: 100;
    flex-direction: column;
    .hms-intro-content {
      width: 100%;
      .hms-intro-content-text1 {
        font-size: 0.95rem;
        padding: 0;
      }
      .hms-intro-content-text2 {
        font-size: 1.5rem;
        margin: 0;
      }
      .hms-intro-content-text3 {
        margin: 10px 0;
        font-size: 0.8rem;
      }
    }
    .hms-intro-img {
      width: 100%;
      margin-top: 15px;
      .hms-img1 {
        width: 85%;
        float: none;
        margin: 0 auto;
        display: block;
      }
    }
  }
  .hms-container{
    .circle2{
      width: 5rem;
      top: 95rem;
     }
     .rectangle2{
      width: 5rem;
      top: 17rem;
     }
 }
  .hms-top-custom strong{
    font-size: 1.1rem;
  }
  .hms-top-heading {
    font-size: 0.9rem;
  }
  .hms-service  .hms-span {
    display: block;
  }
  .hms-service .hms-top-custom strong span{
    display: block;
  }
  .hms strong {
    font-size: 1rem;
  }
  .circle1 {
    display: none;
  }
  .hms-boxes{
    margin: 0 10px;
  }
  .hms-service .hms-boxes {
    margin: 30px 10px;
    padding: 0;
}
  .hms-service-box{
width: 280px;
padding: 1rem;
.hms-service-box-heading{
  font-size: 0.9rem;
}
 .hms-service-box-content{
    font-size: 0.85rem;
    padding: 0.8rem 1rem;
  }}
  .hms-benifits {
    .hms-boxes {
      margin: 0 10px;
      margin-top: 30px;
      column-gap: 5px;
      padding: 0;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      overflow: hidden;
      img {
        width: 40px;
      }
    }
    .hms-benifits-box {
      width: 165px;
      padding: 0.8rem 0.5rem;
      padding-bottom: 5px;
      .hms-benifits-box-top {
        flex-direction: column;
        .hms-benifits-box-top-heading {
          font-size: 0.7rem;
          text-align: center;
          span{
            display:inline;
          }
        }
      }
    }
    .hms-benifits-box::before {
      right: -30px;
      bottom: -30px;
      width: 70px;
      height: 70px;}
    .hms-benifits-box-content {
      display: none;
    }
  }
  .hms-solutions {
    .hms-boxes {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      grid-gap: 10px;
      overflow: hidden;
      margin: 30px 10px;
      .hms-solutions-box {
        width: 152px;
        padding: 15px 5px;
        .hms-solutions-box-img {
          width: 45px;
          height: 45px;
          img {
            width: 30px;
            height: 30px;
          }
        }
        .hms-solutions-box-heading {
          font-size: 0.9rem;
          margin: 0;
        }
        .hms-solutions-box-content {
          font-size: 0.7rem;
          margin: 0;
          line-height: 17px;
        }
      }
    }
  }
}