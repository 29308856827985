$primaryColor: #0c6ed3;
$headingTextColor: #000000;
$secondaryTextColor: #3a3a3a;
$fadeText: #4b4b4b;

$fontFamily: "Noto Sans", sans-serif;

* {
  margin: 0;
  padding: 0;
  text-decoration: none;
  list-style: none;
}
